import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';
import VenueLogin from '../views/Venues/Login.vue';
import Home from '../views/Home.vue';
import Dashboard from '../views/Dashboard.vue'; 
import { getInstance } from "../auth/index";
import { authGuard } from "../auth/authGuard";
import AuthCallback from '../views/callbacks/AuthCallback.vue'; 

import ArtistDirectory from '../views/ArtistDirectory/Index.vue';

import Favourites from '../views/Favourites/Index.vue';
import FavouritesDetails from '../views/Favourites/ID.vue';

import VenueRoutes from './venues';
import GigRoutes from './gig'
import InvoiceRoutes from './invoice'
import CalendarRoutes from './calendar'

import MessagesList from '../views/Messages/Index.vue';
import ArtistMessagesList from '../views/ArtistProfile/Messages/Index.vue';

import ArtistsProfileIndex from '../views/ArtistProfile/Index.vue';
import ArtistProfile from '../views/ArtistProfile/ID.vue';
import ArtistProfileView from '../views/ArtistProfile/Profile.vue';

import ArtistIndex from '../views/ArtistProfile/Dashboard.vue';
import ArtistBidIndex from '../views/ArtistProfile/Bids/Index.vue';
import ArtistAwaitingVenue from '../views/ArtistProfile/Bids/InitialAccept.vue';
import ArtistBidDetails from '../views/ArtistProfile/Bids/ID.vue';
import ArtistBookingIndex from '../views/ArtistProfile/Bookings/Index.vue';
import ArtistFinalAccept from '../views/ArtistProfile/Bids/FinalAcceptance.vue';

import DevIndex from '../views/Dev/Index.vue';
import store from '../state/store';
import axios from 'axios'; 
import client from '../utils/client';
import NotFound from "@/views/NotFound.vue";
import branding from "@/global/branding";

import AppInvoices from '../views/App/Invoices/Index.vue';

import Analytics from "../views/Analytics/Index.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: "/app/artist/invoice/:id",
    name: "AppInvoices",
    meta: { authRequired: true },
    props: true,
    /*beforeEnter: authGuard,*/
    component: AppInvoices
  },
  {
    path: "/dev",
    name: "Dev",
    meta: { authRequired: true },
    props: true,
    /*beforeEnter: authGuard,*/
    component: DevIndex
  },
  {
    path: '/',
    name: 'Login',
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      store
          .dispatch('api/getDomainBrand', window.location.host)
          .then(() => {
            next()
          })

      let $claim = to.query.claim;
      if ($claim){

        localStorage.setItem(process.env.VUE_APP_CLAIM_KEY, $claim); 
        localStorage.setItem('initialRoute', 'artist');
        next('/dashboard');
        
        // presetup claim
      }
      else{
        next();
      }
    },
    meta: { authRequired: false, title: 'Dashboard' },
    component: Home
  },
  {
    path: '/venues/login',
    name: 'VenuesLogin',
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      store
          .dispatch('api/getDomainBrand', window.location.host)
          .then(() => {
            next()
          })
       
        next(); 
    },
    meta: { authRequired: false },
    component: VenueLogin
  },
  {
    path: '/claim',
    name: 'Login',
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
        
        to.params.hide_venues = true;
         
        next(); 
    },
    meta: { authRequired: false },
    component: Home
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: { authRequired: true, title: 'Dashboard' },    
    beforeEnter: (to, from, next) => {  
          if(store.getters['venue/venues']){
            let venue = store.getters['venue/venues'].find(venue => {
              return venue.id === Number(to.params.venueId)
            })
            if (venue) {
              store.dispatch('venue/storeVenue', venue).then(() => {
                next()
              })
            } else {
              next()
            }
          }else{
            next()
          }
      },
    component: Dashboard
  }, 
  {
    path: "/artist/profiles",
    name: "ArtistList",
    meta: { authRequired: true },
    component: ArtistsProfileIndex
  }, 
  {
    path: "/artists/add",
    name: "ArtistProfile",
    meta: { authRequired: true }, 
    component: ArtistProfile,
    beforeEnter:(to, from, next) => {
 
      localStorage.setItem('initialRoute', 'artist');

      store.dispatch('api/getArtistTags', 'api/getTiers').then(function(){
        next();
      });
    }
  },
{
    path: "/artists/:id",
    name: "ArtistEditProfile",
    meta: { authRequired: true },
    props: true,
    beforeEnter: (to, from, next) => {
      store.dispatch("flags/setArtistProfilePageLoaded", false);

      store.getters['flags/artistProfilePageLoaded'].then(() => {
        store.dispatch("flags/setArtistProfilePageLoaded", true);
        client.get('/artists/' + to.params.id + '?include=specialities,specialities.moreInfo,specialities.covers,specialities.genres,specialities.vocals').then((resp) => {
          to.params.artist = resp.data.data;
          to.params.artistId = to.params.id;
          next()
        })
      });
    },
    component: ArtistProfile
  },
  {
    path: "/custom-tags",
    name: "CustomTags",
    meta: { authRequired: true },
    props: true,
    component: () => import('../views/CustomTags/Index.vue') 
  },
  {
    path: "/house-rules",
    name: "VenueHouseRules",
    meta: { authRequired: true },
    component: () => import('../views/Venues/VenueHouseRules.vue') 
  },
  {
    path: "/favourites",
    name: "Favourites",
    meta: { authRequired: true },
    props: true,
    component: Favourites
  },{
    path: "/favourites/:favouriteListId",
    name: "FavouritesDetails",
    meta: { authRequired: true },
    props: true,
    component: FavouritesDetails
  },{
    path: "/artists/profiles/:id",
    name: "ArtistProfileView",
    meta: { authRequired: true },
    props: true,
    /*beforeEnter: authGuard,*/
    beforeEnter: (to, from, next) => {
      
      to.params.is_venue = false;
      
      store.dispatch('api/getArtist', to.params.id).then(function(artist){
          to.params.artist = artist;
          to.params.artistId = Number(to.params.id);
          next();
      });

    },
    component: ArtistProfileView
  },

	// Venue Routes
	...VenueRoutes(store),
	// Gig Routes
	...GigRoutes(store),
	// Invoice Routes
	...InvoiceRoutes(store),
	// Calendar Routes
	...CalendarRoutes(store),
  {
    path: "/artist-directory",
    name: "ArtistDirectory",
    meta: { authRequired: true },
    beforeEnter: (to, from, next) => {
      next();
    },
    component: ArtistDirectory
  },
  {
    path: "/artist-directory/:id/:slug",
    name: "ArtistDirectoryPage",
    meta: { authRequired: true, title: 'Artist Directory' },
    props: true,
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
 
        next();
    },
    component: ArtistProfileView
  },
  {
    path: "/requests-to-play",
    name: "RequestsToPlay",
    meta: { authRequired: true },
    beforeEnter: (to, from, next) => {
      next();
    },
    component: () => import('../views/RequestsToPlay/Index.vue') 
  },
  {
    path: "/auth/callback",
    name: "Callback", 
    beforeEnter: authGuard,
    component: AuthCallback
  },
  {
    path: "/messages/offer/:gigID/:bidID",
    name: "BidMessages", 
    meta: { authRequired: true },
    component: MessagesList, 
    props: true
    
  }, 
  {
    path: "/messages/gig/:gigID",
    name: "GigMessages", 
    meta: { authRequired: true },
    component: MessagesList, 
    props:true    
  }, 
  {
    path: "/artist/messages",
    name: "ArtistMessages", 
    meta: { authRequired: true },
    component: ArtistMessagesList, 
    beforeEnter: (to, from, next) => {
      {
        store
          .dispatch('api/getArtistBids')
          .then(() => {
            next()
          })
      }
    }
  }, 
  {
    path: "/messages",
    name: "Messages", 
    meta: { authRequired: true },
    component: MessagesList, 
    props: true
  }, 
  {
    path: "/artist",
    name: "ArtistIndex",
    meta: { authRequired: true },
    component: ArtistIndex
  },
  {
    path: "/artist/awaiting-venue-response",
    name: "AwaitingVenue",
    meta: { authRequired: true, title: "Availability given and awaiting response from venues" },
    component: ArtistAwaitingVenue
  },
  {
    path: "/artist/offers",
    name: "ArtistBidIndex",
    meta: { authRequired: true, title: "New available gigs" },
    component: ArtistBidIndex,
    /* beforeEnter: (to, from, next) => {
      
      store
        .dispatch('api/getArtistBids')
        .then(() => {
          next()
        })
       
    }, */
  },
  {
    path: "/artist/min-fee-offers",
    name: "ArtistCounterBidIndex",
    meta: { authRequired: true, title: "Gigs under minimum fee" },
    component: () => import('../views/ArtistProfile/CounterBids/Index.vue') 
  },
  {
    path: "/artist/house-rules",
    name: "ArtistHouseRules",
    meta: { authRequired: true, title: "GigPig house rules" },
    component: () => import('../views/ArtistProfile/Rules/ArtistHouseRules.vue') 
  },
  {
    path: "/artist/venue-directory",
    name: "ArtistVenueDirectory",
    meta: { authRequired: true },
    component: () => import('../views/ArtistProfile/VenueDirectory/ArtistVenueDirectory.vue') 
  },
  {
    path: "/artist/final-acceptance",
    name: "FinalAcceptance",
    meta: { authRequired: true },
    component: ArtistFinalAccept,
    beforeEnter: (to, from, next) => {
      store
        .dispatch('api/getArtistBids')
        .then(() => {
          next()
        })
    },
  },
  {
    path: "/artist/gigs",
    name: "ArtistBookingIndex",
    meta: { authRequired: true, title: "Your booked gigs" },
    component: ArtistBookingIndex
  },
  {
    path: "/artist/offers/:id",
    name: "ArtistBidDetails",
    meta: { authRequired: true },
    props: true,
    component: ArtistBidDetails
  },
  {
    path: "/artist/bookings/:id",
    name: "ArtistGigDetails",
    meta: { authRequired: true },
    props: true,
    component: ArtistBidDetails
  },
  {
    path: "/Analytics",
    name: "Analytics",
    meta: { authRequired: true },
    component: Analytics,
    props: true
  },
  {
    path: "/opus-booking",
    name: "OpusBooking",
    meta: { authRequired: false, useOpus: true}, 
    beforeEnter: () => {
      console.log("opus");
    }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    meta: { authRequired: true },
    props: true,
    component: NotFound
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition){
    
    return { top: 0 }
  }
})

router.beforeEach((to, from, next) => {

  const domainBrand = store.getters['api/domainBrand'];

  console.log('domainBrand beforeEach', domainBrand)
console.log('window.location.host', window.location.host)
  if (!domainBrand) {
    store
        .dispatch('api/getDomainBrand', window.location.host)
        .then(() => {
          console.log('domainBrand beforeEach after request', store.getters['api/domainBrand'])
          next()
        })
  }
  
  // If this isn't an initial page load.
  // if (to.name) {
  //   // Start the route progress bar.
  //   NProgress.start()
  // }
  next()
})

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})


// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  const authService = getInstance();

  // Check if auth is required on this route
  // (including nested routes).
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired)

  if (window.sessionStorage.getItem('opus')){
    store.dispatch('user/getUsersData');
    store.dispatch('user/getUsersRoles');
    return next();
  }

  // If auth isn't required for the route, just continue.
  if (!authRequired) return next()

  const fn = async () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {

      // Get the token
      const token  = await Vue.prototype.$auth.getTokenSilently()

      // Set the token in the axios default headers
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
      client.defaults.headers.common["Authorization"] = `Bearer ${token}`
      
      let claimKey = localStorage.getItem(process.env.VUE_APP_CLAIM_KEY); 

      if (claimKey)
      {
        let $key = claimKey;
        localStorage.removeItem(process.env.VUE_APP_CLAIM_KEY);
        router.push({name: 'ArtistProfile', query: { 'claim' : $key }});
      }
			
      Promise.all([
        // User
        store.dispatch('user/getUsersData')
        .then(() => {
        }, err => {
          console.log(err)
        }),
        // User Roles
        store.dispatch('user/getUsersRoles')
        .then(() => {
          //
        }, err => {
          console.log(err)
        }),
        // Load filters
        store.dispatch('api/loadInitialFilters')
        .then(() => {
          //
        }, err => {
          console.log(err)
        })
      ]).finally(() => {
        //
      });
      //
      return next();
    }
    let params = branding.auth0Params({})
    // Otherwise, log in 
    authService.loginWithRedirect({ appState: { targetUrl: routeTo.fullPath }, ...params });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", loading => {
    if (loading === false) {
      return fn();
    }
  });
})

export default router
