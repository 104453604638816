<template>
<div class="ml-4">
    
    <InlineNotification class="bg_singer" Heading="Pending gigs">
        <template slot="body">
          <div class="grid grid-flow-row gap-4">
          <p>Below is the list of gigs that you have offered to artists but have not confirmed yet, and upcoming booked gigs.</p>
          <p>Click “view offers” to see who is available, and confirm the artist for that gig.</p>
          <p>If there are no available artists, let GigPig find available artists in your area.
          </p></div>
        </template>
    </InlineNotification>


    <div>
      <div class="mt-12 flex-row lg:flex lg:space-x-24 space-y-6 lg:space-y-0">
        <div>
          <div class="flex space-x-2 items-center">
            <OfficeBuildingIcon class="text-gp_pink-default-500"/>
            <span class="font-black">Venues</span>
          </div>
          <div class="w-80 mt-4">
            <MultiselectFilter
            :options="compVenues"
            :selectedOptionProps="selectedVenues"
            :searchable="true"
            :multiple="true"
            :taggable="false"
            :close-on-select="false"
            :show-labels="false"
            :displayText="venueDisplayText"
            @optionSelected="venueSelected"
            @optionDeselected="venueDeselected"
            :placeHolder="'Filter by venue'"
            :track-by="'id'"
            ></MultiselectFilter>
          </div>
        </div>
      </div>


      <div class="mt-12 flex-row lg:flex lg:space-x-24 space-y-6 lg:space-y-0">
        <div>
            <div class="flex space-x-2 items-center">
              <ClipboardListIcon class="text-gp_pink-default-500"/>
              <span class="font-black">Gig Status</span>
            </div>
            <div class="w-80 mt-4">
              <MultiselectFilter
                :options="statuses"
                :selectedOptionProps="selectedStatuses"
                :searchable="false"
                :multiple="true"
                :taggable="false"
                :close-on-select="false"
                :show-labels="false"
                :displayText="statusDisplayText"
                @optionSelected="statusSelected"
                @optionDeselected="statusDeselected"
                :placeHolder="'Filter by status'"
                :track-by="'status'"
                ></MultiselectFilter>
            </div>
        </div>
        <div v-show="isGenre">
            <div class="flex space-x-2 items-center">
              <TagIcon class="text-gp_pink-default-500"/>
              <span class="font-black">Gig Tags</span>
            </div>
            <div class="w-80 mt-4">
              <TRichSelect 
                multiple
                v-model="setTagFilters"
                :selectedOptionProps="setTagFilters"
                ref="iconselect"  
                class="col-span-12 lg:col-span-5 cursor-pointer"
                :close-on-select="false" valueAttribute="id"  textAttribute="name"
                :options="venue_custom_tags"
                placeholder="Filter by custom tag" 
              >  
                <template v-show="setTagFilters" slot="option" slot-scope="{ index, isHighlighted, isSelected, className, option }">                                 
                  <div class="flex gap-4 py-2">
                    <component :is="icons[option.raw.icon]" class="ml-3"/>  {{ option.raw.name }} 
                  </div>
                </template>
              </TRichSelect> 
            </div>
        </div>
      </div>

      <div class="mt-12">
        <div class="flex space-x-2 items-center">
          <CalendarIcon class="text-gp_pink-default-500"/>
          <span class="font-black">Filter by date</span>
        </div>
        <div class="flex-row lg:flex lg:space-x-9 space-y-3 lg:space-y-0 mt-4">
          <div>
            <div class="col-span-3 lg:col-span-1">
              <input :min="dateMinDate" ref="start" placeholder="Start date" type="date" v-model="dateFilterStart" class="datepick cursor-pointer h-full w-80 rounded-lg border-gray-300"/>
            </div>
            <p class="text-xs font-thin ml-1 mt-2">The start date of the gigs to display</p>
          </div>
          <div class="hidden lg:block">
            <ArrowCircleRightIcon class="text-gp_pink-default-400 mt-4" />
          </div>
          <div>
            <div class="col-span-3 lg:col-span-1">
              <input ref="end" type="date" v-model="dateFilterEnd" class="datepick h-full w-80 cursor-pointer rounded-lg border-gray-300"/>
            </div>
            <p class="text-xs font-thin ml-1 mt-2">The end date of the gigs to display</p>
          </div>
        </div>
      </div>
    </div>

    <!-- NEW BUILD UI REVAMP -->
    <div class="mt-16 py-2 bg-[#0D1C43] rounded-lg max-w-5xl flex space-x-44 uppercase text-gray-200 text-sm font-medium">
        <div class="ml-4"/>
        <div class="w-52 flex space-x-3 invisible xl:visible">
          <div class="bg-gp_pink-default-400 w-[2px] h-5 rounded-lg"/>
          <span>Gig Details</span>
        </div>
        <div class="hidden xl:flex space-x-3 pl-16">
          <div class="bg-gp_pink-default-400 w-[2px] h-5 rounded-lg"/>        
          <span>Gig Status</span>
        </div>

    </div>

    <div v-if="isLoading" class="mt-8 space-y-4 max-w-5xl">
        <div v-for="i in 3" :key="i" class="animate-pulse flex flex-col lg:flex-row py-8 border rounded-xl shadow-lg bg-white">
            <div class="flex">
                <div class="flex flex-col justify-center items-center px-10 w-48">
                    <div class="rounded-full h-24 w-24 bg-gray-200"></div>
                </div>
                <div class="w-96 flex space-x-5">
                    <div class="bg-gray-200 w-[2px] h-full rounded-lg"></div>
                    <div class="space-y-3 w-full">
                        <div class="h-4 bg-gray-200 rounded w-3/4"></div>
                        <div class="h-4 bg-gray-200 rounded w-1/2"></div>
                        <div class="h-4 bg-gray-200 rounded w-1/4"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-else class="mt-8 space-y-4 max-w-5xl">
        <div v-for="(gig, index) in rows" 
             :key="'chaserow' + gig.id" 
             class="flex flex-col lg:flex-row py-8 border rounded-xl shadow-lg bg-white transition-all duration-200 hover:shadow-xl"
        >
            <div class="flex">
                <div class="flex flex-col justify-center items-center px-10 w-48">
                    <img :src="imageCheck(gig.venue.icon)" 
                         class="rounded-full h-24 w-24 object-cover border-2 border-gp_pink-default-400" />
                </div>

                <div class="w-96 flex space-x-5">
                    <div class="bg-gp_pink-default-400 w-[2px] h-full rounded-lg"/>   
                    <div>
                        <GigTagByStatus
                            class="lg:hidden"
                            :status="gig.status"
                        />
                        <p class="font-bold mt-2 lg:mt-0">{{gig.venue.name}}</p>
                        <p class="mt-2">{{gig.name}}</p>
                        <span class="block">{{fullDate(gig.start)}}</span>

                        <div class="mt-2 mb-4 space-x-3 flex items-center">
                            <div v-if="gig.custom_tags.length > 0" class="flex items-center justify-center px-3 py-1 rounded-3xl space-x-2 bg-black ">
                                <component :is="icons[firstCustomTagIconName(gig.custom_tags[0])]" class="w-4 h-4" :style="{ color: firstCustomTagIconColour(gig.custom_tags[0]) }"/>
                                <span class="text-white text-xs">{{ firstCustomTagName(gig.custom_tags[0]) }}</span>
                            </div>
                            <div>
                                <span class="relative inline-flex flex-col items-center group" v-if="gig.custom_tags.length > 0">
                                    <span v-if="gig.custom_tags.length > 1"
                                    class="text-sm font-medium underline underline-offset-2 cursor-pointer"
                                    >+{{ gig.custom_tags.length - 1 }} more</span>
                                    <div class="absolute z-10 bottom-0 flex-col items-center hidden mb-6 group-hover:flex">
                                    <span class="p-4 bg-black rounded-sm shadow-lg w-48">
                                        <ul class="list-none">
                                        <li v-for="tag in gig.custom_tags" :key="tag.id" class="text-white flex space-x-4 items-center space-y-1">
                                            <component :is="icons[tag.icon]" class="w-5 h-5" :style="{ color: tag.icon_colour }"/>
                                            <span>{{ tag.name }}</span>
                                        </li>
                                        </ul>
                                    </span>
                                    <div class="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
                                    </div>
                                </span>
                            </div>
                        </div>

                        <div class="flex items-center space-x-2">
                            <router-link :to="getGigRoute(gig)"> 
                                <button class="rounded-full py-1.5 px-4 text-xs font-semibold cursor-pointer tracking-wider text-white  bg-gradient-to-r from-pink-400 via-gp_pink-default-500 to-gp_pink-default-500 hover:bg-gp_pink-default-500 hover:text-white transition ease-out duration-700">{{ getGigRouteLabel(gig) }}</button>
                            </router-link>

                            <button v-show="hasTagAccess && isGenre" @click="openUpdateTagsModal(index, gig)" class="rounded-full py-1 px-4 text-xs font-semibold cursor-pointer tracking-wider text-gp_pink-default-600 border-gp_pink-default-500 border-2 hover:bg-pink-400 hover:text-white transition ease-out duration-700">Manage Tags</button>
                        </div>
                    </div>
                </div>

                <div class="w-96 pl-16 hidden lg:block">
                    <GigTagByStatus
                        :status="gig.status"
                    />

                    <div class="">
                        <div class="uppercase text-xs mt-3" v-if="gig.cancelled"> 
                            <span>This gig has been cancelled.</span>
                        </div>
                        <div class="uppercase text-xs mt-3" v-else-if="gig.status === 'UNPUBLISHED'"> 
                            <span class="">Start choosing artists.</span>
                        </div>
                        <div class="uppercase text-xs mt-3 space-y-1" v-else-if="gig.bookings.length == 0"> 
                            <span>{{gig.bids_count}} offers found</span>
                            <span class="block">{{timeUntilGig(gig.start)}}</span>
                        </div>
                        <div class="" v-else-if="gig.bookings && gig.bookings[0] && gig.bookings[0].artist"> 


                            <div class="flex items-center space-x-3 mt-3">
                                <ClockIcon class="w-4 h-4"/>
                                <span class="block uppercase font-light text-xs">{{timeUntilGig(gig.start)}}</span>
                            </div>


                            <div class="flex space-x-4">
                                <div class="flex flex-col justify-center items-center mt-6">
                                    <img :src="artistImageCheck(gig.bookings[0].artist)"
                                         class="rounded-full h-12 w-12 object-cover border-2 border-gp_pink-default-400" />
                                </div>
                                <div class="mt-6">
                                    <span class="text-gp_pink-default-500" v-if="gig.bookings && gig.bookings[0].artist">{{gig.bookings[0].artist.name}} booked</span>
                                    <!-- <span class="block text-indigo-500" v-if="gig.bookings[0].artist.telephone"><a :href="'tel:' + gig.bookings[0].artist.telephone">tel: {{gig.bookings[0].artist.telephone }}</a></span> -->
                                    
                                    <div class="flex items-center space-x-2 mt-1">
                                        <PhoneIcon class="w-4 h-4"/>
                                        <span class="text-sm">{{gig.bookings[0].artist.telephone }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="rows.length === 0 && !isLoading" class="mt-8 max-w-5xl p-8 text-center border rounded-xl bg-white">
        <p class="text-gray-500">No gigs found matching your filters.</p>
    </div>

    <div class="max-w-5xl shadow overflow-hidden border-b mt-4">
        <Pagination
            @changePage="handlePageChange"
            :paginate_from="paginate_from"
            :paginate_to="paginate_to"
            :paginate_total="paginate_total"
            :paginate_currPage="paginate_currPage"
            :paginate_lastPage="paginate_lastPage"
        ></Pagination>
    </div>

    <AmendCustomGigTag ref="updateGigTags" v-on:updatedTag="updatedTags($event)"/> 
</div>
</template>

<script type="text/javascript">


import { apiComputed, apiMethods } from '@/state/helpers.js'
import fullDate from  '@/utils/format-date-full.js'  
import InlineNotification from '../../components/notifications/inline.vue'; 
import NProgress from 'nprogress';
import Pagination from "@/components/elements/Pagination.vue";
import { DateTime } from 'luxon';
import { timeUntilGig } from '@/utils/time-until-gig.js';
import { OfficeBuildingIcon, ClipboardListIcon, CalendarIcon, ArrowCircleRightIcon, PhoneIcon, ClockIcon, TagIcon  } from "@vue-hero-icons/outline";
import GigTagByStatus from '../../components/iconsets/GigTagByStatus.vue'
import MultiselectFilter from '@/components/filters/MultiSelectFilter.vue';
import AmendCustomGigTag from '../../components/modal/gigs/AmendCustomTags.vue';
import  * as icons from "@vue-hero-icons/outline" 
import {mapState} from "vuex";
import client from '@/utils/client.js'
import { TRichSelect } from 'vue-tailwind/dist/components';
import { debounce } from 'lodash';

export default {
    name: "ChaseArtists",
    components: {
        Pagination,
        InlineNotification,
        OfficeBuildingIcon, ClipboardListIcon, CalendarIcon, ArrowCircleRightIcon, PhoneIcon, ClockIcon, TagIcon,
        GigTagByStatus,
        MultiselectFilter,
        AmendCustomGigTag,
        TRichSelect
    },
    data() { 
        return {
            setTagFilters: [],
            venue_custom_tags: [],
            icons: icons,
            paginate_from: null,
            paginate_to: null,
            paginate_total: null,
            paginate_currPage: 1,
            paginate_lastPage: null,
            selectedVenues: [],
            dateMinDate: DateTime.now().toFormat('yyyy-MM-dd'),
            selectedStatuses: [{status: 'all', name: 'All'}],
            dateFilterStart: this.getCalendarStartDate(),
            dateFilterEnd: this.getCalendarEndDate(),
            rowsCopy: [],
            rows: [],
            compVenues: [],
            selectedIndex: null,
            statuses: [
                {
                    name: 'All',
                    status: 'all'
                },
                {
                    name: 'Confirmed',
                    status: 'CONFIRMED'
                },
                {
                    name: 'Pending Response',
                    status: 'NEEDS_RESPONSE'
                },
                {
                    name: 'Searching for Artists',
                    status: 'PENDING'
                },
                {
                    name: 'Draft',
                    status: 'UNPUBLISHED'
                },
                {
                    name: 'Cancelled',
                    status: 'CANCELLED'
                },
            ],
            isLoading: false,
            cachedResponses: new Map(),
            lastFetchTimestamp: null,
            CACHE_DURATION: 5 * 60 * 1000, // 5 minutes cache
            isInitialLoad: true,
            isInitializing: false // Add flag to prevent watcher triggers during initialization
        }
    },
    watch:{
        setTagFilters(){
            if (this.isInitializing) return;
            localStorage.setItem('pendingGigsTagFilters', JSON.stringify(this.setTagFilters));
            this.debouncedLoadData();
        },
        paginate_currPage() {
            if (this.isInitializing) return;
            localStorage.setItem('pendingGigsPage', this.paginate_currPage);
            this.debouncedLoadData();
        },
        selectedVenues(){
            if (this.isInitializing) return;
            if (this.selectedVenues.length === 0) {
                this.selectedVenues = [{id: 'all', name: 'All'}];
            }
            localStorage.setItem('pendingGigsVenueFilter', JSON.stringify(this.selectedVenues));
            this.paginate_currPage = 1;
            this.debouncedLoadData();
        },
        dateFilterStart() {
            if (this.isInitializing) return;
            localStorage.setItem('pendingGigsStartDate', this.dateFilterStart);
            this.paginate_currPage = 1;
            this.debouncedLoadData();
        },
        dateFilterEnd() {
            if (this.isInitializing) return;
            localStorage.setItem('pendingGigsEndDate', this.dateFilterEnd);
            this.paginate_currPage = 1;
            this.debouncedLoadData();
        },
        selectedStatuses: {
            handler(newVal) {
                if (this.isInitializing) return;
                if (newVal.length === 0) {
                    this.selectedStatuses = [{status: 'all', name: 'All'}];
                }
                localStorage.setItem('pendingGigsStatuses', JSON.stringify(this.selectedStatuses));
                this.paginate_currPage = 1;
                this.debouncedLoadData();
            },
            deep: true
        }
    },
    computed: {
        ...apiComputed,
        ...mapState({
            userRoles: (state) => state.user.rolesPermissionsSlugs,
        }),
        hasTagAccess()
        {
            if (this.userRoles && this.userRoles.length > 0) {
                return this.userRoles[0] == 'full-access';
            } else {
                return null; 
            }
        },
        userData(){
            return this.$store.getters['user/userData']
        },
        isGenre() {
            return this.userData && this.userData.email.includes('genremusic')
        },
        getGigRoute(){
            return (gig) => {
                if(!gig.published){
                    return `gigs/${gig.id}/choose-artists`
                }else{
                    if(gig.status === "PENDING" && gig.bids_count){
                        return `gigs/${gig.id}/offers`
                    }
                    if(gig.status === "CONFIRMED" && gig.bookings.length){
                        return `gigs/${gig.id}/bookings`
                    }
                    return `gigs/${gig.id}`
                }
                
            }
        },
        getGigRouteLabel(){
            return (gig) => {
                
                if(!gig.published){
                    return 'Choose artists'
                }else{
                    if(gig.status === "PENDING" && gig.bids_count){
                        return 'View offers'
                    }
                    if(gig.status === "CONFIRMED" && gig.bookings.length){
                        return 'View bookings'
                    }
                    return `View gig`
                }
                
            }
        },
        statusParams() {
            const stats = this.selectedStatuses[0].status === 'all' ? this.statuses.slice(1) : this.selectedStatuses;
            return stats.map((status) => status.status);
        },
        venueParams() {
            if (!this.compVenues.length || (this.compVenues[0].id === 'all' && this.compVenues.length === 1))
                return [];

            if (!this.selectedVenues.length || (this.selectedVenues[0].id === 'all' && this.selectedVenues.length === 1))
                return [];

            const venues = this.selectedVenues[0].id === 'all' ? this.compVenues.slice(1) : this.selectedVenues;
            return venues.map((venue) => venue.id);
        },
        statusDisplayText() {
            return this.selectedStatuses[this.selectedStatuses.length-1].name
        },
        venueDisplayText() {
            return this.selectedVenues.length ? this.selectedVenues[this.selectedVenues.length-1].name : 'All venues'
        }
    },
    mounted() {
        // Start loading gigs when component is mounted
        this.initializeGigs();
    },
    methods: {
        ...apiMethods,
        fullDate, 
        timeUntilGig,
        firstCustomTagName(val){
            return val.name
        },
        firstCustomTagIconName(val){
            return val.icon
        },
        firstCustomTagIconColour(val){
            return val.icon_colour
        },
        updatedTags(event) {
            this.rows[this.selectedIndex].custom_tags = event;
            this.rowsCopy[this.selectedIndex].custom_tags = event;
        },
        openUpdateTagsModal(index, gig) {
            this.selectedIndex = index;
            this.$refs.updateGigTags.updateGigTag(gig);
        },
        artistImageCheck(artist){
            return artist.image && artist.image.url ? artist.image.url : require('@/assets/images/bg_concert.png')
        },
        imageCheck(icon){
            return icon && icon.url ? icon.url : require('@/assets/images/bg_concert.png')
        },
        getCacheKey(params) {
            return JSON.stringify({
                page: params.page,
                dates: params.starts_between,
                statuses: params.statuses,
                venues: params.venue_ids,
                tags: params.custom_tags
            });
        },
        async loadData() {
            if (this.isLoading) return;
            this.isLoading = true;
            NProgress.start();

            try {
                const params = {
                    page: this.paginate_currPage,
                    starts_between: `${this.dateFilterStart},${this.dateFilterEnd}`,
                    include: ['venue', 'bookings', 'customTags'].join(','),
                    statuses: this.statusParams,
                    venue_ids: this.venueParams,
                    custom_tags: this.setTagFilters
                };

                const cacheKey = JSON.stringify(params);
                const now = Date.now();
                const cached = this.cachedResponses.get(cacheKey);

                if (cached && (now - cached.timestamp) < this.CACHE_DURATION) {
                    this.rows = cached.data;
                    this.rowsCopy = cached.data;
                    this.paginate_from = cached.meta.from;
                    this.paginate_to = cached.meta.to;
                    this.paginate_total = cached.meta.total;
                    this.paginate_lastPage = cached.meta.last_page;
                } else {
                    const response = await this.getPendingGigs(params);
                    
                    this.rows = response.data;
                    this.rowsCopy = response.data;
                    this.paginate_from = response.meta.from;
                    this.paginate_to = response.meta.to;
                    this.paginate_total = response.meta.total;
                    this.paginate_lastPage = response.meta.last_page;

                    this.cachedResponses.set(cacheKey, {
                        data: response.data,
                        meta: response.meta,
                        timestamp: now
                    });
                    this.cleanupCache();
                }
            } catch (error) {
                console.error('Error loading data:', error);
                this.$notify({ 
                    title: 'Error loading gigs', 
                    text: 'Please try refreshing the page. If the problem persists, contact support.',
                    type: 'error',
                    duration: 5000
                });
                throw error;
            } finally {
                this.isLoading = false;
                NProgress.done();
            }
        },
        async loadVenues(){
            await client
                .get('venues-filter-list')
                .then((response) => {
                    this.compVenues = [{ name:"All venues", id:"all" }, ...response.data.data ];
            })
        },
        selectedStat(status) {
            return status === this.selectedStatuses[this.selectedStatuses.length - 1].status;
        },
        selectedVen(id) {
            return id === this.selectedVenues[this.selectedVenues.length - 1].id;
        },
        statusSelected(option) {
            if (option.status === 'all') {
                this.selectedStatuses = [{status: 'all', name: 'All'}];
            } else {
                localStorage.setItem('pendingGigsTagFilter', JSON.stringify(option));
                const index = this.selectedStatuses.findIndex((element) => element.status === 'all');
                if (index !== -1) {
                    this.selectedStatuses.splice(index, 1);
                }
                this.selectedStatuses.push(option);
            }
        },
        statusDeselected(option) {
            if (option.status !== 'all') {
                const index = this.selectedStatuses.findIndex((element) => element.status === option.status);
                if (index !== -1) {
                    this.selectedStatuses.splice(index, 1);
                }
            }
        },
        venueSelected(option) {
            if (option.id === 'all') {
                this.selectedVenues = [{id: 'all', name: 'All venues'}];
            } else {
                const index = this.selectedVenues.findIndex((element) => element.id === 'all');
                if (index !== -1) {
                    this.selectedVenues.splice(index, 1);
                }
                this.selectedVenues.push(option);
                localStorage.setItem('pendingGigsVenueFilter', JSON.stringify(this.selectedVenues));
            }
        },
        venueDeselected(option) {
            if (option.id !== 'all') {
                const index = this.selectedVenues.findIndex((element) => element.id === option.id);
                if (index !== -1) {
                    this.selectedVenues.splice(index, 1);
                    if (this.selectedVenues.length === 0) {
                        localStorage.removeItem('pendingGigsVenueFilter');
                    } else {
                        localStorage.setItem('pendingGigsVenueFilter', JSON.stringify(this.selectedVenues));
                    }
                }
            }
        },
        getCalendarStartDate() {
            const pendingGigsStartDate = localStorage.getItem('pendingGigsStartDate')
            if (pendingGigsStartDate) {
                return pendingGigsStartDate
            } else {
                return DateTime.now().toFormat('yyyy-MM-dd')
            }
        },
        getCalendarEndDate() {
            const pendingGigsEndDate = localStorage.getItem('pendingGigsEndDate')
            if (pendingGigsEndDate) {
                return pendingGigsEndDate
            } else {
                return DateTime.now().plus({months: 2}).toFormat('yyyy-MM-dd')
            }
        },
        getTagFilters() {
            // First try to get the selected filters
            const pendingCustomTags = JSON.parse(localStorage.getItem('pendingGigsTagFilters'));
            if(pendingCustomTags){
                this.setTagFilters = pendingCustomTags;
            }
            // Then load the available tags from cache
            const cachedTags = JSON.parse(localStorage.getItem('gigpig_custom_tags'));
            if(cachedTags) {
                this.venue_custom_tags = cachedTags;
            }
        },
        getVenueFilters() {
            // First try to get the selected filters
            const pendingVenueFilters = JSON.parse(localStorage.getItem('pendingGigsVenueFilter'));
            if(pendingVenueFilters && pendingVenueFilters.length) {
                this.selectedVenues = pendingVenueFilters;
                console.log(this.selectedVenues);
            } else {
                this.selectedVenues = [];
            }
            // Then load the available venues from cache
            const cachedVenues = JSON.parse(localStorage.getItem('gigpig_venues_list'));
            if(cachedVenues) {
                this.compVenues = cachedVenues;
            }
        },
        getGigStatusFilter() {
            const pendingGigsStatusFilter = JSON.parse(localStorage.getItem('pendingGigsStatuses'));
            if(pendingGigsStatusFilter) {
                this.selectedStatuses = pendingGigsStatusFilter;
            } else {
                this.selectedStatuses = [{status: 'all', name: 'All'}];
            }
        },
        getCurrentPage() {
            const currentPage = localStorage.getItem('pendingGigsCurrentPage');
            if (currentPage) {
                this.paginate_currPage = parseInt(currentPage);
            } else {
                this.paginate_currPage = 1;
            }
        },
        debouncedLoadData: debounce(function() {
            this.loadData();
        }, 500), // Increased debounce time to 500ms
        async initializeGigs() {
            NProgress.start();
            try {
                this.isInitializing = true;
                
                // Initialize filters synchronously from localStorage
                this.getCurrentPage();
                this.getTagFilters();
                this.getVenueFilters();
                this.getGigStatusFilter();
                
                // Load gigs and supporting data in parallel
                const [gigsResponse, tagsResponse, venuesResponse] = await Promise.all([
                    this.getPendingGigs({
                        page: this.paginate_currPage,
                        starts_between: `${this.dateFilterStart},${this.dateFilterEnd}`,
                        include: ['venue', 'bookings', 'customTags'].join(','),
                        statuses: this.statusParams,
                        venue_ids: this.venueParams,
                        custom_tags: this.setTagFilters
                    }),
                    client.get('custom-tags'),
                    client.get('venues-filter-list')
                ]);

                // Update the component state with all the responses
                this.venue_custom_tags = tagsResponse.data.data;
                this.compVenues = [{ name: "All venues", id: "all" }, ...venuesResponse.data.data];
                
                this.rows = gigsResponse.data;
                this.rowsCopy = gigsResponse.data;
                this.paginate_from = gigsResponse.meta.from;
                this.paginate_to = gigsResponse.meta.to;
                this.paginate_total = gigsResponse.meta.total;
                this.paginate_lastPage = gigsResponse.meta.last_page;

                // Cache the gigs response
                const cacheKey = JSON.stringify({
                    page: this.paginate_currPage,
                    starts_between: `${this.dateFilterStart},${this.dateFilterEnd}`,
                    statuses: this.statusParams,
                    venue_ids: this.venueParams,
                    custom_tags: this.setTagFilters
                });
                
                this.cachedResponses.set(cacheKey, {
                    data: gigsResponse.data,
                    meta: gigsResponse.meta,
                    timestamp: Date.now()
                });
                this.cleanupCache();

            } catch (error) {
                console.error('Error initializing gigs:', error);
                this.$notify({ 
                    title: 'Error loading data', 
                    text: 'Please try refreshing the page',
                    type: 'error',
                    duration: 5000
                });
            } finally {
                this.isInitializing = false;
                this.isInitialLoad = false;
                NProgress.done();
            }
        },
        handlePageChange(newPage) {
            // Scroll to top of the gig list when changing pages
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.paginate_currPage = newPage;
        },
        cleanupCache() {
            const now = Date.now();
            for (const [key, value] of this.cachedResponses.entries()) {
                if (now - value.timestamp > this.CACHE_DURATION) {
                    this.cachedResponses.delete(key);
                }
            }
        }
    },
    directives: {
        lazy: {
            mounted(el, binding) {
                function loadImage() {
                    if (el.tagName === 'IMG') {
                        el.addEventListener('load', () => {
                            el.classList.add('loaded');
                        });
                        el.src = binding.value;
                    }
                }

                function handleIntersect(entries, observer) {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) {
                            loadImage();
                            observer.unobserve(el);
                        }
                    });
                }

                const observer = new IntersectionObserver(handleIntersect, {
                    root: null,
                    threshold: 0
                });

                observer.observe(el);
            }
        }
    }
}

</script>

<style>
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s;
}
.fade-leave-to, .fade-enter {
    opacity: 0;
}

img {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

img:not([src]) {
    opacity: 0;
}

.loaded {
    opacity: 1;
}
</style>