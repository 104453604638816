import client from "../../utils/client"

export const state = {
  venue: null,
	venues: null,
	tags:null
}

export const mutations = {
  SET_VENUE(state, venue) {
    state.venue = venue
  },

	SYNC_VENUE(state, venue) {
		if (!state.venue) {
			return
		}
    state.venue = {
			...state.venue,
			...venue
		}
  },

	SET_VENUES(state, venues) {
    state.venues = venues
  },

	SET_TAGS(state, tags) {
    state.tags = tags
  },
}

export const getters = {
	currentVenue: state => {
    return state.venue;
  },
	venues: state => {
    return state.venues;
  },
	tags: state => {
    return state.tags;
  },
}

export const actions = {
	storeVenue({ commit }, venue){
		console.log("STORE CURRENT VENUE", venue.id, venue.name)
		commit('SET_VENUE', venue);
	},
	createVenue({getters, dispatch}, payload){
		return new Promise((resolve, reject) => {

			client
				.post('venues', payload)
				.then((response) => {
					// commit('SET_VENUE', response.data.data);
					dispatch('storeVenue', response.data.data)
					resolve(getters.currentVenue)
				})
				.catch(err => {
					console.log(err)
					reject(err)
				});
			
		})
	},
	updateCurrentVenue({getters, commit}, payload){
		return new Promise((resolve, reject) => {

			client
				.put(`venues/${ getters.currentVenue.id }`, payload)
				.then((response) => {
					commit('SYNC_VENUE', response.data.data);
					resolve(getters.currentVenue)
				})
				.catch(err => {
					console.log(err)
					reject(err)
				});
			
		})
	},

	syncVenue({ getters, commit }, venueIdentity) {
		return new Promise((resolve) => {
			commit('SYNC_VENUE', venueIdentity)
			resolve(getters.currentVenue)
		})
	},

  getCurrentVenue({ getters, dispatch }, venueId) {
		return new Promise((resolve) => {
				client
					.get(`/venues/${venueId}`)
					.then((response) => {
						// commit('SET_VENUE', response.data.data);
						dispatch('storeVenue', response.data.data)
						resolve(getters.currentVenue)
					})
					.catch(err => {
						console.log(err)
					});
		})
  },

	getCurrentVenueWithUsagesAndInvoices({ getters, dispatch }, venueId) {
		return new Promise((resolve) => {
				client
					.get(`/venues/${venueId}`, { 
						params: {
							include: ['invoices', 'usages', 'usages.usageable'].join(',')
						}
					})
					.then((response) => {
						// commit('SET_VENUE', response.data.data);
						dispatch('storeVenue', response.data.data)
						resolve(getters.currentVenue)
					})
					.catch(err => {
						console.log(err)
					});
		})
  },

	getTags({ getters, commit }) {
		return new Promise((resolve) => {
			if(getters.tags){
				resolve(getters.tags)
			}else{
				client
					.get('venue-tags')
					.then((response) => {
						commit('SET_TAGS', response.data.data);
						resolve(getters.tags)
					})
					.catch(err => {
						console.log(err)
					});
			}
		})
  },

	getAllVenues({ commit }) {
		return new Promise((resolve) => {
			client
				.get('venues')
				.then((response) => {
					commit('SET_VENUES', response.data.data);
					resolve(response.data.data)
				})
				.catch(err => {
					console.log(err)
				});
		})
  },

	getVenuesList() {
		return new Promise((resolve) => {
			client
				.get('venues-list')
				.then((response) => {
					resolve(response.data.data)
				})
				.catch(err => {
					console.log('Error in getVenuesList', err)
				});
		})
	},

	getVenuesName() {
		return new Promise((resolve) => {
			client
				.get('venues-name')
				.then((response) => {
					resolve(response.data.data)
				})
				.catch(err => {
					console.log('Error in getVenuesName', err)
				});
		})
	},
	getVenuesCalendarList() {
		return new Promise((resolve) => {
			client
				.get('venues-calendar-list')
				.then((response) => {
					resolve(response.data.data)
				})
				.catch(err => {
					console.log('Error in getVenuesCalendarList', err)
				});
		})
	},
	getVenuesCurrencyList() {
		return new Promise((resolve) => {
			client
				.get('venues-currency-list')
				.then((response) => {
					resolve(response.data.data)
				})
				.catch(err => {
					console.log('Error in getVenuesCurrencyList', err)
				});
		})
	},
} 
